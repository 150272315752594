p {
  font-family: 'Secular One', sans-serif;
}

.bookButton {
  transition-duration: 3s;
}

.bookButton:hover {
  border: 1px solid black;
}
