.footer{
    width: 100%;
    /* space gray: */
    background-color: #1e3231;


    height: 25vh;
}

.footer-icon-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 30px;
    padding: 2rem 2rem 1rem 1rem;
}

.footer-icon, svg{
    color: white;
}

